import SEO from "../../components/seo";
import Wrapper from "../../layout/wrapper";

import { useState } from "react";
import { toast } from "react-toastify";
import { signupv2 } from "../../services/user-service";
import { setDataInLocalStorage } from "../../browser-storage";
import { LocalStorageConstants } from "../../services/localstorageconstants";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import config from "../../services/config";
const StudentSignUp = () => {
  const navigate = useNavigate();
  const [error, seterror] = useState();
  const [details, setDetails] = useState({
    email: "",
    password: "",
    firstname: "",
    lastname: "",
    mobile: "",
    registered_by_hostname: window.location.hostname,
  });

  const onSignupSuccess = ({ email, userName, _id, token }) => {
    setDataInLocalStorage(LocalStorageConstants.USER_DETAILS, {
      userId: _id,
      email: email,
      userName: userName,
    });
    setDataInLocalStorage(LocalStorageConstants.IS_LOGGED_IN, true);
    setDataInLocalStorage(LocalStorageConstants.TOKEN, token);

    //COMMENTING TILL WE GET EMAIL SUPPORT BACK
    // const emailresp = emailActivationLink(email).then((resp)=>{
    //   console.log(resp)
    //   emailrespstore = resp
    // }).catch(err=>{
    //   console.log(err)

    // })
    // if(emailrespstore){
    // addToast('Activation email sent, Please check your email', {
    //    appearance: 'success',
    //     autoDismiss: true,
    //   })
    // }
    // console.log(emailresp)
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    if (!details || !details.password || !details.email) return;

    const res = await signupv2(
      details.email,
      details.password,
      details.firstname,
      details.lastname,
      details.mobile,
      details.registered_by_hostname
    )
      .then((resp) => {
        console.log(resp);
        toast.success(`Successfully Created`, {
          position: "top-left",
        });
        onSignupSuccess(resp);
        navigate("/student-login", { replace: true });
      })
      .catch((err) => {
        console.log(err);
        if (err) {
          toast.error(`Error : ${err.response.data}`, {
            position: "top-left",
          });
        }
      });
    console.log(res);
  };

  return (
    <Wrapper>
      <SEO pageTitle={""} />
      <section className="signup__area po-rel-z1 pt-100 pb-145">
        <div className="sign__shape">
          <img className="man-1" src="/assets/img/icon/sign/man-3.png" alt="" />
          <img
            className="man-2 man-22"
            src="/assets/img/icon/sign/man-2.png"
            alt=""
          />
          <img
            className="circle"
            src="/assets/img/icon/sign/circle.png"
            alt=""
          />
          <img
            className="zigzag"
            src="/assets/img/icon/sign/zigzag.png"
            alt=""
          />
          <img className="dot" src="/assets/img/icon/sign/dot.png" alt="" />
          <img className="bg" src="/assets/img/icon/sign/sign-up.png" alt="" />
          <img
            className="flower"
            src="/assets/img/icon/sign/flower.png"
            alt=""
          />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-xxl-8 offset-xxl-2 col-xl-8 offset-xl-2">
              <div className="section__title-wrapper text-center mb-55">
                <h2 className="section__title">
                  Create a free <br /> Account
                </h2>
                {/* <p>I'm a subhead that goes with a story.</p> */}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-6 offset-xxl-3 col-xl-6 offset-xl-3 col-lg-8 offset-lg-2">
              <div className="sign__wrapper white-bg">
                <div className="sign__header mb-35"></div>
                <div className="sign__form">
                  <>
                    <div className="sign__input-wrapper mb-25">
                      <h5>First Name</h5>
                      <div className="sign__input">
                        <input
                          value={details.firstname}
                          onChange={(e) =>
                            setDetails({
                              ...details,
                              firstname: e.target.value,
                            })
                          }
                          type="text"
                          placeholder="First Name"
                          name="firstname"
                          autocapitalize="none"
                        />
                        <i className="fal fa-user"></i>
                      </div>
                    </div>
                    <div className="sign__input-wrapper mb-25">
                      <h5>Last Name</h5>
                      <div className="sign__input">
                        <input
                          value={details.lastname}
                          onChange={(e) =>
                            setDetails({
                              ...details,
                              lastname: e.target.value,
                            })
                          }
                          type="text"
                          placeholder="Last Name"
                          name="lastname"
                          autocapitalize="none"
                        />
                        <i className="fal fa-user"></i>
                      </div>
                    </div>
                    <div className="sign__input-wrapper mb-25">
                      <h5>Mobile Number</h5>
                      <div className="sign__input">
                        <input
                          value={details.mobile}
                          onChange={(e) =>
                            setDetails({ ...details, mobile: e.target.value })
                          }
                          type="number"
                          placeholder="Mobile Number"
                          name="mobile"
                        />
                        <i className="fal fa-user"></i>
                      </div>
                    </div>
                    <h5>Email</h5>
                    <div className="sign__input">
                      <input
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Email"
                        onChange={(e) =>
                          setDetails({ ...details, email: e.target.value })
                        }
                        value={details.email}
                        className="profile-input-field"
                        autoComplete="off"
                        autocapitalize="none"
                      />
                      <i className="fal fa-user"></i>
                    </div>
                    <div className="sign__input-wrapper mb-25">
                      <h5>Password</h5>
                      <div className="sign__input">
                        <input
                          type="password"
                          name="password"
                          id="password"
                          placeholder="Password"
                          autoComplete="off"
                          onChange={(e) =>
                            setDetails({
                              ...details,
                              password: e.target.value,
                            })
                          }
                          className="profile-input-field"
                          value={details.password}
                          autocapitalize="none"
                        />
                        <i className="fal fa-lock"></i>
                      </div>
                    </div>
                    {error != "" ? <div className="error">{error}</div> : ""}

                    <button
                      type="button"
                      onClick={submitHandler}
                      className="e-btn w-100"
                    >
                      <span></span> Sign Up
                    </button>
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Wrapper>
  );
};

export default StudentSignUp;
