import SEO from "../../components/seo";
import Wrapper from "../../layout/wrapper";
import { Link } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import {
  GetCourseByMentorId,
  AddMentorCourse,
  EditCourseListing,
  DeleteCourseListing,
  GetCourseByKeyword,
} from "../../services/mentor-service";
import { getDataFromLocalStorage } from "../../browser-storage";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Badge from "react-bootstrap/Badge";
import Container from "react-bootstrap/Container";
import Accordion from "react-bootstrap/Accordion";
import Table from "react-bootstrap/Table";
import { toast } from "react-toastify";
import JoditEditor from "jodit-react";
import * as DOMPurify from "dompurify";
import config from "../../services/config";
import MentorSliderMenu from "./mentorslidermenu";
import { v4 as uuid } from "uuid";
import { GetMentorProfile } from "../../services/mentor-service";
import {
  AddMentorDynamicTabs,
  getDynamicTabsDatabyMentorId,
  UpdateDynamicCourseListing,
  UpdateDynamicTabsListing,
} from "../../services/dynamictabs-service";

const MentorManageDynamicTabs = () => {
  const [dynamictabslisting, setDynamicTabListing] = useState([]);
  const [courseListing, setCourseListing] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [coursecreated, setcoursecreated] = useState("false");
  const MentorDetials = getDataFromLocalStorage("user-details");
  useEffect(() => {
    getDynamicTabsListingByMentorId();
  }, []);

  let getDynamicTabsListingByMentorId = async () => {
    console.log(MentorDetials);
    const getmentorname = MentorDetials.fullName;
    const getmentorusername = MentorDetials.userName;
    const profileImgUrl = MentorDetials.profileImgUrl;
    const uniquecoursecode = uuid();
    const currentemail = MentorDetials.userName;
    console.log("uniquecoursecode", uniquecoursecode);
    console.log("currentemail", currentemail);
    setDynamicTabsDetails({
      dynamicTabForMentorId: currentemail,
    });

    const SubmitResponse = await getDynamicTabsDatabyMentorId(currentemail)
      .then((resp) => {
        console.log(resp);
        const respreverse = resp.reverse();
        const getArr = resp[0].dynamicTabsArray;

        setCourseListing(getArr);
        setDynamicTabListing(respreverse);
        if (resp.length > 0) {
          setcoursecreated("true");

          // Perform additional actions if needed
        }

        //GetMentorProfileById(currentemail);
      })
      .catch((err) => console.log(err));
  };

  const [DynamicTabsDetails, setDynamicTabsDetails] = useState({
    dynamicTabForMentorId: "",
    dynamicTabsArray: [],
  });
  let onChange = (evt) => {
    const value = evt.target.value;
    setDynamicTabsDetails({
      ...DynamicTabsDetails,
      [evt.target.name]: value,
    });
    console.log(DynamicTabsDetails);
  };
  useEffect(() => {
    getDynamicTabsDatabyMentorId();
  }, []);

  let EditHandler = (item) => {
    console.log(item);
    console.log(item.dynamicTabForMentorId);
    setEditMode(true);
    setDynamicTabsDetails({
      dynamicTabForMentorId: item.dynamicTabForMentorId,
      dynamicTabsArray: item.dynamicTabsArray,
    });
    setDynamicTabs(item.dynamicTabsArray);
  };

  //   let DeleteHandler = (item) => {
  //     console.log(item);
  //     const dynamicTabForMentorId = item.course_code;
  //     DeleteCourseListing(course_code).then((resp) => {
  //       console.log(resp);
  //       getCourseListingByMentor();
  //     });
  //   };

  const [DynamicTabs, setDynamicTabs] = useState([
    {
      dynamicTabId: "",
      dynamicTabTitle: "",
      dynamicTabCondition: "",
      dynamicTabOrder: "",
      //   dynamicTabCourseId: "",
      //   dynamicTabLink: "",
      //   dynamicTabIcon: "",
      //   dynamicTabAlt: "",
    },
  ]);
  useEffect(() => {
    // This code will run when the component mounts
    const initialDynamicTab = {
      dynamicTabId: "",
      dynamicTabTitle: "",
      dynamicTabCondition: generateRandomId(),
      dynamicTabOrder: "",
    };

    setDynamicTabs([initialDynamicTab]);
  }, []); // The empty dependency array ensures this effect runs only once on mount

  // useEffect(() => {
  //   // This code will run when the component mounts
  //   const newDynamicTab = {
  //     dynamicTabId: "",
  //     dynamicTabTitle: "",
  //     dynamicTabCondition: generateRandomId(),
  //     dynamicTabOrder: "",
  //     // dynamicTabCourseId: "",
  //     // dynamicTabLink: "",
  //     // dynamicTabIcon: "",
  //     // dynamicTabAlt: "",
  //   };

  //   setDynamicTabs({ dynamicTabCondition: generateRandomId() });
  // }, []); // The empty dependency array ensures this effect runs only once on mount

  let DynamicTabshandleChange = (i, e) => {
    let value = [...DynamicTabs];
    value[i][e.target.name] = e.target.value;
    DynamicTabs[i][e.target.name] = value[i][e.target.name];
    setDynamicTabs(DynamicTabs);
    setDynamicTabs([...DynamicTabs]);
    console.log(DynamicTabs);
  };

  const generateRandomId = () => {
    // Function to generate a random unique 8-character string
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let randomId = "";
    for (let i = 0; i < 8; i++) {
      randomId += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    let finalcode = MentorDetials.userName + "_" + randomId;
    return finalcode;
  };

  let addDynamicTabsFields = () => {
    setDynamicTabs([
      ...DynamicTabs,
      {
        dynamicTabId: "",
        dynamicTabTitle: "",
        dynamicTabCondition: generateRandomId(),
        dynamicTabOrder: "",
        //   dynamicTabCourseId: "",
        //   dynamicTabLink: "",
        //   dynamicTabIcon: "",
        //   dynamicTabAlt: "",
      },
    ]);
  };

  let removeDynamicTabsFields = (i) => {
    let value = [...DynamicTabs];
    value.splice(i, 1);
    setDynamicTabs(value);
  };

  let SubmitDynamicTabsDetials = async () => {
    console.log("DynamicTabs", DynamicTabs);
    DynamicTabsDetails.dynamicTabsArray = DynamicTabs;
    console.log(DynamicTabsDetails);

    const SubmitResponse = await AddMentorDynamicTabs(DynamicTabsDetails)
      .then((resp) => {
        toast.success(`Submit Successfully `, {
          position: "top-left",
        });
        window.location.reload();
      })
      .catch((err) =>
        toast.error(`Error ${err} `, {
          position: "top-left",
        })
      );
  };

  let SubmitEditDynamicTabsDetails = async () => {
    DynamicTabsDetails.dynamicTabsArray = DynamicTabs;
    console.log(DynamicTabsDetails);
    const SubmitResponse = await UpdateDynamicTabsListing(DynamicTabsDetails)
      .then((resp) => {
        toast.success(`Submit Successfully `, {
          position: "top-left",
        });
      })
      .catch((err) =>
        toast.error(`Error ${err} `, {
          position: "top-left",
        })
      );
  };

  const switchtoaddmode = () => {};

  //Add Video Part
  const [CourseDetails, setCourseDetails] = useState({
    course_code: "",
    course_keyword: "",
    course_title: "",
    course_createdby_mentor: "",
    course_mentor_profileImgUrl: "",
    course_category: "",
    course_duration: "",
    course_description: "",
    course_short_description: "",
    course_price: "Free",
    course_banner_link: "",
    course_student_enrolled: "",
    course_liked: "",
    course_shared: "",
    course_created_by_our_mentor: true,
    course_created_date: "",
    userEmailId: "",
    course_videos_count: "",
    course_videos: [],
  });

  const [CourseVideosDetials, setCourseVideosDetials] = useState([
    {
      video_title: "",
      video_embed_code: "",
      video_description: "",
      video_duration: "",
    },
  ]);

  let CourseVideoshandleChange = (i, e) => {
    let newCourseVideos = [...CourseVideosDetials];
    newCourseVideos[i][e.target.name] = e.target.value;
    CourseVideosDetials[i][e.target.name] = newCourseVideos[i][e.target.name];
    setCourseVideosDetials(CourseVideosDetials);
    setCourseVideosDetials([...CourseVideosDetials]);
    console.log(CourseVideosDetials);
  };

  let addCourseVideosFormFields = () => {
    setCourseVideosDetials([
      ...CourseVideosDetials,
      {
        video_title: "",
        video_embed_code: "",
        video_description: "",
        video_duration: "",
      },
    ]);
  };

  let removeCourseVideosFormFields = (i) => {
    let newCourseVideosValues = [...CourseVideosDetials];
    newCourseVideosValues.splice(i, 1);
    setCourseVideosDetials(newCourseVideosValues);
  };

  let SubmitEditCourseDetails = async () => {
    const SubmitResponse = await EditCourseListing(CourseDetails)
      .then((resp) => {
        toast.success(`Submit Successfully `, {
          position: "top-left",
        });
      })
      .catch((err) =>
        toast.error(`Error ${err} `, {
          position: "top-left",
        })
      );
  };

  const [current_video_for_update, setCurrent_video_for_update] = useState([]);

  const AddCourseIdtoVideo = async (item) => {
    console.log("AddCourseIdtoVideo", item);
    const resp = await GetCourseByKeyword(item.dynamicTabCondition).then(
      (resp) => {
        console.log("resp", resp);
        let current_video = resp[0].course_videos;
        console.log("current_videos", current_video);
        //setCurrent_video_for_update(current_video);
        setCourseVideosDetials(current_video);
      }
    );
    // setCourseDetails({
    //   course_keyword: item.dynamicTabCondition,
    //   course_title: item.dynamicTabTitle,
    // });
  };
  let SubmitCourseDetials = async () => {
    // console.log('Course Details', CourseDetails)
    // console.log('Module Details', ModuleDetials)
    // console.log('ModuleVideosDetials', ModuleVideosDetials)
    console.log("CourseVideosDetials", CourseVideosDetials);
    CourseDetails.course_videos = CourseVideosDetials;
    console.log(CourseDetails);

    const SubmitResponse = await AddMentorCourse(CourseDetails)
      .then((resp) => {
        toast.success(`Submit Successfully `, {
          position: "top-left",
        });
      })
      .catch((err) =>
        toast.error(`Error ${err} `, {
          position: "top-left",
        })
      );
  };
  return (
    <Wrapper>
      <SEO pageTitle={"Manage Courses"} />

      <Container>
        <Row style={{ marginTop: "50px" }}>
          {dynamictabslisting.map((item) => (
            <Card className="carddesign" key={item.uniqueKey}>
              <Container className="jobcontainer">
                <Row style={{ padding: 15 }}>
                  <Col sm={8}>
                    <p className="jobtitle">
                      <b className="boldtitle">
                        Courses for Mentor Id: {item.dynamicTabForMentorId}
                      </b>
                    </p>
                  </Col>
                  <Col>
                    <button className="e-btn" onClick={() => EditHandler(item)}>
                      Edit
                    </button>
                  </Col>
                  <Col>
                    {" "}
                    {/* <Col>
          <button className="e-btn" onClick={() => DeleteCourseHandler(item)}>
            Delete
          </button>
        </Col> */}
                  </Col>
                </Row>
              </Container>
            </Card>
          ))}
        </Row>
      </Container>

      <Container>
        <Row>
          <Card className="carddesign marginTop5">
            <Card.Body>
              <Card.Text>
                <Accordion defaultActiveKey="1">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Add / Manage Course</Accordion.Header>
                    <Accordion.Body>
                      <Card className="MainCard">
                        {editMode == false ? (
                          <>
                            <Col>
                              <div className="section__title-wrapper mb-45">
                                <h2 className="section__title">
                                  <span className="yellow-bg">
                                    Add{" "}
                                    <img
                                      src="/assets/img/shape/yellow-bg-2.png"
                                      alt=""
                                    />
                                  </span>
                                  Course
                                </h2>
                              </div>
                            </Col>
                          </>
                        ) : (
                          <>
                            <Container>
                              <Row>
                                <Col sm={8}>
                                  <div className="section__title-wrapper mb-45">
                                    <h2 className="section__title">
                                      Edit{" "}
                                      <span className="yellow-bg">
                                        Course{" "}
                                        <img
                                          src="/assets/img/shape/yellow-bg-2.png"
                                          alt=""
                                        />
                                      </span>
                                      Listing
                                    </h2>
                                  </div>
                                </Col>
                                {/* <Col sm={4}>
                        <button className="e-btn" onClick={switchtoaddmode}>
                          Switch To Add Mode
                        </button>
                      </Col> */}
                              </Row>
                            </Container>
                          </>
                        )}

                        <Container>
                          <div className="MobTextStyle">
                            <input
                              placeholder="Mentor Email Id"
                              className="form-control marginTop5"
                              type="text"
                              name="dynamicTabForMentorId"
                              value={DynamicTabsDetails.dynamicTabForMentorId}
                              disabled
                            />
                          </div>
                          <Card className="carddesign marginTop5">
                            <Card.Body>
                              <Card.Title>Courses</Card.Title>
                              <Card.Text>
                                {editMode == true ||
                                coursecreated == "false" ? (
                                  <>
                                    {DynamicTabs.map((element, index) => (
                                      <div className="form-inline" key={index}>
                                        <input
                                          placeholder="Course Name"
                                          className="form-control"
                                          type="text"
                                          name="dynamicTabTitle"
                                          value={element.dynamicTabTitle}
                                          onChange={(e) =>
                                            DynamicTabshandleChange(index, e)
                                          }
                                        />
                                        <input
                                          placeholder="Unique Keyword for Course"
                                          className="form-control"
                                          type="text"
                                          name="dynamicTabCondition"
                                          value={element.dynamicTabCondition}
                                          onChange={(e) =>
                                            DynamicTabshandleChange(index, e)
                                          }
                                          disabled
                                          style={{ display: "none" }}
                                        />

                                        {index ? (
                                          <button
                                            type="button"
                                            className="btn btn-primary button remove"
                                            onClick={() =>
                                              removeDynamicTabsFields(index)
                                            }
                                          >
                                            Remove
                                          </button>
                                        ) : null}
                                        <hr />
                                      </div>
                                    ))}
                                    <div className="button-section">
                                      <button
                                        className="e-btn"
                                        type="button"
                                        onClick={() => addDynamicTabsFields()}
                                      >
                                        Add
                                      </button>
                                    </div>
                                  </>
                                ) : null}
                              </Card.Text>
                            </Card.Body>
                          </Card>
                        </Container>
                        {editMode == false ? (
                          <>
                            {coursecreated}
                            {coursecreated == "false" ? (
                              <>
                                <button
                                  className="e-btn"
                                  onClick={SubmitDynamicTabsDetials}
                                >
                                  Submit
                                </button>
                              </>
                            ) : (
                              <>
                                You have already created course, please use edit
                                to create more courses
                              </>
                            )}
                          </>
                        ) : (
                          <button
                            className="e-btn marginTop5"
                            onClick={SubmitEditDynamicTabsDetails}
                          >
                            Submit
                          </button>
                        )}
                      </Card>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Card.Text>
            </Card.Body>
          </Card>
        </Row>
      </Container>
      <Card style={{ margin: 15 }} className="carddesign marginTop5">
        <Card.Body>
          <Card.Text>
            <Accordion defaultActiveKey="1">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Course List</Accordion.Header>
                <Accordion.Body>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Index</th>
                        <th style={{ width: "60%" }}>Course Name</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {courseListing.map((element, index) => {
                        return (
                          <>
                            <tr>
                              <td>{index + 1}</td>
                              <td>{element.dynamicTabTitle}</td>
                              <td>
                                <Row>
                                  <Col>
                                    <button
                                      className="e-btn"
                                      onClick={() =>
                                        AddCourseIdtoVideo(element)
                                      }
                                    >
                                      Add Video
                                    </button>
                                  </Col>
                                  <Col>
                                    <button className="e-btn">Add PDF</button>
                                  </Col>
                                  <Col>
                                    <button className="e-btn">Add Quiz</button>
                                  </Col>
                                </Row>
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </Table>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Card.Text>
        </Card.Body>
      </Card>
      <Card style={{ margin: 15 }} className="carddesign marginTop5">
        <Card.Body>
          <Card.Text>
            <Accordion defaultActiveKey="1">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Add Video</Accordion.Header>
                <Accordion.Body>
                  {CourseVideosDetials.map((element, index) => (
                    <div className="form-inline" key={index}>
                      <input
                        placeholder="Video Title"
                        className="form-control"
                        type="text"
                        name="video_title"
                        value={element.video_title}
                        onChange={(e) => CourseVideoshandleChange(index, e)}
                      />
                      <input
                        placeholder="Add video link"
                        className="form-control"
                        type="text"
                        name="video_embed_code"
                        value={element.video_embed_code}
                        onChange={(e) => CourseVideoshandleChange(index, e)}
                      />

                      {index ? (
                        <button
                          type="button"
                          className="btn btn-primary button remove"
                          onClick={() => removeCourseVideosFormFields(index)}
                        >
                          Remove
                        </button>
                      ) : null}
                      <hr />
                    </div>
                  ))}
                  <div className="button-section">
                    <button
                      className="e-btn"
                      type="button"
                      onClick={() => addCourseVideosFormFields()}
                    >
                      Add
                    </button>
                  </div>
                  <button className="e-btn" onClick={SubmitCourseDetials}>
                    Add Submit
                  </button>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Card.Text>
        </Card.Body>
      </Card>
    </Wrapper>
  );
};

export default MentorManageDynamicTabs;
