import { useState } from "react";

import MIXSkillPage from "./single-skill-page";
import MixFinishPage from "./single-finish";

import { submitMixSkillTest } from "../../../services/assessment";

import { useDispatch, useSelector } from "react-redux";
import { getQueryParams } from "../../../helpers";
import { AppConfigEvent } from "../../../redux/types/redux-event";

import TestHeader from "../assessment/test-header/TestHeader";
import {
  Container,
  PageContainer,
  PageHeader,
  Span,
  CheckBoxBlock,
  Checkbox,
  AcceptText,
  BeginBtn,
} from "./single-assessment-css-functions";

const MixBeginPage = () => {
  console.log("this component is from new-single-assessments");
  const answerSheet = useSelector((state) => state.assessment.answerSheet);
  const testId = useSelector((state) => state.assessment.testId);
  const assessmentType = useSelector(
    (state) => state.assessment.assessmentType
  );

  const dispatch = useDispatch();
  const [assessmentId, setId] = useState(getQueryParams("id") || "");
  const [pageNumb, setPage] = useState(0);
  const [tstId, setTestId] = useState("");
  const [assessmntType, setType] = useState("MIX");
  const setData = (testId, type, pageNumber) => {
    setTestId(testId);
    setType(type);
    setPage(pageNumber);
  };
  const onTestSubmit = async () => {
    console.log("Check if begin ontestsubmit works");
    dispatch({ type: AppConfigEvent.START_LOADING });
    const res = await submitMixSkillTest({ testId: testId, answerSheet });
    console.log("res", res);
    if (!res) return;
    setData(testId, assessmentType, 2);
    dispatch({ type: AppConfigEvent.STOP_LOADING });
  };
  return (
    <Container>
      <TestHeader
        headerConfig={{
          ghLogo: true,
          searchBar: false,
          home: false,
          msg: false,
          notification: false,
          avatarControl: true,
          timeTicker: pageNumb === 0 || pageNumb === 1,
        }}
        onTestSubmit={onTestSubmit}
        pageNum={pageNumb}
      />
      {pageNumb === 0 && (
        <PageContainer>
          <PageHeader> {"Personality Assessment"}</PageHeader>
          <Span>
            Our Personality Assessment will help you understand where you stand
            on 5 key personality traits that are essential for your life and
            career:
          </Span>
          <Span>There are no right or wrong answers.</Span>
          <Span>
            Your honest assessment of yourself will help you identify your true
            personality
          </Span>
          <Span>
            This section is not timed. Typically it takes 5 minutes to finish
            this section
          </Span>
          <Span>
            Once you submit your responses, we will provide you a detailed
            scorecard with feedback
          </Span>
          <BeginBtn
            assessmentId={!assessmentId}
            disabled={!assessmentId}
            onClick={() => setPage(1)}
          >
            Begin
          </BeginBtn>
        </PageContainer>
      )}
      {pageNumb === 1 && (
        <MIXSkillPage
          assessmentId={assessmentId}
          assessmentType={"MIX"}
          setPage={setData}
        />
      )}
      {pageNumb === 2 && (
        <MixFinishPage testId={tstId} assessmentType={assessmntType} />
      )}
    </Container>
  );
};

export default MixBeginPage;
